.box {
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    border-radius: 5px;
    color:white;
    padding: 0px;
    height: 190px;
    width: 250px;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    text-align: center;
    transition: 0.3s;
}
.box:hover {
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    border-radius: 5px;
    color:white;
    height: 250px;
    width: 250px;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
}

.img-box {
    height: 190px;
    width: 190px;
    top:-80px;
    object-fit: cover;
    border-radius: 6px;
    /* border: 1px solid black;  */
    box-shadow: 0 3px 5px 2px rgba(255, 142, 164, 0.3);
    position: relative;
    background-color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    
}

.prof-container {
    margin: 10px;
    width: fit-content;
    padding-top: 80px;
    
}
.name{
    margin-top: -75px;
    font-size: 27px;
    /* font-size: x-large; */
    text-transform: uppercase;
}
.des {
    margin-top: -24px;
    font-size: 20px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
}